/**************************************
    Default Styles
***************************************/

@import "bootstrap/dist/css/bootstrap.min.css";
// @import url('../css/bootstrap/bootstrap-utilities.css');
// @import url('../css/bootstrap/bootstrap-grid.css');
@import url("../css/slick.css");
@import url("../css/slicktheme.css");
@import url("../css/animation.css");

@import "default/variables";
@import "default/mixins";
@import "default/reset";
@import "default/typography";
@import "default/extend";
@import "default/animations";
@import "default/shortcode";
@import "default/spacing";
@import "default/common";
@import "default/forms";

/**************************************
    Theme Styles
***************************************/

@import "color/themebg";

/**************************************
    Header Styles
***************************************/
@import "header/header";
@import "header/nav";
@import "header/mobilemenu";
@import "header/headertop";

/**************************************
    Elements Styles
***************************************/
@import "elements/sctiontitle";
@import "elements/button";
@import "elements/breadcrumb";
@import "elements/accordion";
@import "elements/service";
@import "elements/card";
@import "elements/progressbar";
@import "elements/counter";
@import "elements/portfolio";
@import "elements/social";
@import "elements/team";
@import "elements/timeline";
@import "elements/testimonial";
@import "elements/slickslide";
@import "elements/tab";
@import "elements/pricing";
@import "elements/split";
@import "elements/callto-action";
@import "elements/video";
@import "elements/contact";
@import "elements/brand";
@import "elements/newsletter";
@import "elements/advance-tab";
@import "elements/advancepricing";
@import "elements/about";
@import "elements/swicher";
@import "elements/404";

/**************************************
    Blog Styles
***************************************/

@import "blog/blog";
@import "blog/sidebar";
@import "blog/blog-details";

/**************************************
    Footer Styles
***************************************/
@import "template/banner";
@import "template/portfoliodetails";
@import "template/splash";

/**************************************
    Footer Styles
***************************************/

@import "footer/footer";
@import "footer/copyright";
@import "footer/darkmode";

.accordion-container {
	position: relative;
}

.discover-papyon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
}

.bgr-white {
	background-color: white;
}

.bg-papyon {
	background: #eceff1 !important;
}

.hd-papyon {
	background: #eceff1 !important  ;
}

.typedColor {
	color: #060606 !important;
	background-color: #ff5252;
}
.hColor {
	color: #060606 !important;
}
.roomColor {
	color: #ff5252 !important;
}

.buttonColor {
	color: #060606;
	// background-color: #060606 !important;
	border: 2px solid #ff5252 !important;
	padding: 0 28px;
	// border-style: solid;
	border-width: 2px;
	border-color: transparent;
	border-radius: 25px;
	// background-color: transparent;
	transition-duration: 300ms;
	font-size: 16px;
	font-weight: 400;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	height: 50px;
	line-height: 47px;
	letter-spacing: 0.5px;
	width: auto;
}

.serviceIcons {
	border: 3px solid #ff5252;
	color: #ff5252 !important;
}

.bold {
	font-weight: bold !important;
}

.faqText {
	font-weight: bold;
	color: #ff5252 !important;
}

.contactButton {
	background: #ff5252;
	color: white !important;
	// border: 2px solid #060606 !important;
	padding: 0 28px;
	border-style: none;
	// border-width: 2px;
	// border-color: transparent;
	border-radius: 25px;
	// background-color: transparent;
	transition-duration: 300ms;
	font-size: 16px;
	font-weight: bold;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	height: 50px;
	line-height: 47px;
	letter-spacing: 0.5px;
	width: auto;
}
.section-title-2 {
	color: #060606 !important;
}
body,
html {
	scroll-behavior: smooth;
}
